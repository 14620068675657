





































import { computed, defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { useStoreConfig } from '~/features/storeConfig';

export default defineComponent({
  name: 'BlackFridayCounter',
  setup() {
    const timeLeft = ref({
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    });
    const showTimer = computed(() => {
      return (
        timeLeft.value.days !== '00' ||
        timeLeft.value.hours !== '00' ||
        timeLeft.value.minutes !== '00' ||
        timeLeft.value.seconds !== '00'
      );
    });
    let intervalId: ReturnType<typeof setInterval>;
    const { blackFridayEndDate } = useStoreConfig();

    const endDateTime = computed(() => {
      return new Date(blackFridayEndDate.value).getTime();
    });

    const updateTimeLeft = () => {
      const currentTime = new Date().getTime();
      if (!endDateTime.value) return;

      const diff = endDateTime.value - currentTime;
      if (diff < 0) {
        clearInterval(intervalId);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      timeLeft.value = {
        days: days < 10 ? `0${days}` : days.toString(),
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
        seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
      };
    };

    onMounted(() => {
      updateTimeLeft();
      intervalId = setInterval(updateTimeLeft, 1000);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return {
      timeLeft,
      showTimer,
    };
  },
});
